<template>
  <div class="qa-page">
    <div class="qa-header">
      <h1>
        服务质量评价
      </h1>
    </div>
    <div style="padding: 0 10px">


    <p style="text-indent: 0;text-align: left;">
      亲爱的客户：
    </p>
    <p style="text-indent: 2em;text-align: left;">
      非常感谢您对艾尔兴广东服务中心的大力支持和信任：本中心将一如既往地站在客户的立场，为客户的利益尽职尽责，争取客户最大的满意，客户的完全满意是我们奋斗的目标。为了进一步了解客户对我们的服务情况，我们将展开客户满意度调查工作，您的任何意见和建议都是我们的宝贵财富，并将激励我们更加努力地工作，不断改进提高，最终为您提供更优质的服务。
    </p>
    <mt-field placeholder="请填写使用艾尔兴的孩子姓名" label="1. 孩子的姓名" class="required"  type="text" v-model="form.fullName"></mt-field>
    <p style="text-indent: 0; margin-left: 10px;text-align: left;">
      2.服务质量评价：
    </p>
    <a data-v-426df720="" class="mint-cell mint-field required select">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title">
          <span class="mint-cell-text">1）服务人员的态度</span>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.fuwutaidu" value="5" id="fuwutaidu5" name="fuwutaidu" />
            <label for="fuwutaidu5">非常满意</label>
          </div>
          <div class="male">
            <input type="radio" v-model="form.fuwutaidu" value="4" id="fuwutaidu4" name="fuwutaidu" />
            <label for="fuwutaidu4">满意</label>
          </div>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.fuwutaidu" value="3" id="fuwutaidu3" name="fuwutaidu" />
            <label for="fuwutaidu3">一般</label>
          </div>
          <div class="male">
            <input type="radio" v-model="form.fuwutaidu" value="2" id="fuwutaidu2" name="fuwutaidu" />
            <label for="fuwutaidu2">不满意</label>
          </div>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.fuwutaidu" value="1" id="fuwutaidu1" name="fuwutaidu" />
            <label for="fuwutaidu1">非常不满意</label>
          </div>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>

    <a data-v-426df720="" class="mint-cell mint-field required select">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title">
          <span class="mint-cell-text">2）服务人员的技术水平</span>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.fuwushuiping" value="5" id="fuwushuiping5" name="fuwushuiping" />
            <label for="fuwushuiping5">非常满意</label>
          </div>
          <div class="male">
            <input type="radio" v-model="form.fuwushuiping" value="4" id="fuwushuiping4" name="fuwushuiping" />
            <label for="fuwushuiping4">满意</label>
          </div>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.fuwushuiping" value="3" id="fuwushuiping3" name="fuwushuiping" />
            <label for="fuwushuiping3">一般</label>
          </div>
          <div class="male">
            <input type="radio" v-model="form.fuwushuiping" value="2" id="fuwushuiping2" name="fuwushuiping" />
            <label for="fuwushuiping2">不满意</label>
          </div>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.fuwushuiping" value="1" id="fuwushuiping1" name="fuwushuiping" />
            <label for="fuwushuiping1">非常不满意</label>
          </div>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>

    <a data-v-426df720="" class="mint-cell mint-field required select">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title">
          <span class="mint-cell-text">3）整体的服务技术水平</span>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.zhengtishuiping" value="5" id="zhengtishuiping5" name="zhengtishuiping" />
            <label for="zhengtishuiping5">非常满意</label>
          </div>
          <div class="male">
            <input type="radio" v-model="form.zhengtishuiping" value="4" id="zhengtishuiping4" name="zhengtishuiping" />
            <label for="zhengtishuiping4">满意</label>
          </div>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.zhengtishuiping" value="3" id="zhengtishuiping3" name="zhengtishuiping" />
            <label for="zhengtishuiping3">一般</label>
          </div>
          <div class="male">
            <input type="radio" v-model="form.zhengtishuiping" value="2" id="zhengtishuiping2" name="zhengtishuiping" />
            <label for="zhengtishuiping2">不满意</label>
          </div>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.zhengtishuiping" value="1" id="zhengtishuiping1" name="zhengtishuiping" />
            <label for="zhengtishuiping1">非常不满意</label>
          </div>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>

    <a data-v-426df720="" class="mint-cell mint-field required select">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title">
          <span class="mint-cell-text">4）服务的种类</span>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.fuwuzhonglei" value="5" id="fuwuzhonglei5" name="fuwuzhonglei" />
            <label for="fuwuzhonglei5">非常满意</label>
          </div>
          <div class="male">
            <input type="radio" v-model="form.fuwuzhonglei" value="4" id="fuwuzhonglei4" name="fuwuzhonglei" />
            <label for="fuwuzhonglei4">满意</label>
          </div>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.fuwuzhonglei" value="3" id="fuwuzhonglei3" name="fuwuzhonglei" />
            <label for="fuwuzhonglei3">一般</label>
          </div>
          <div class="male">
            <input type="radio" v-model="form.fuwuzhonglei" value="2" id="fuwuzhonglei2" name="fuwuzhonglei" />
            <label for="fuwuzhonglei2">不满意</label>
          </div>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.fuwuzhonglei" value="1" id="fuwuzhonglei1" name="fuwuzhonglei" />
            <label for="fuwuzhonglei1">非常不满意</label>
          </div>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>

    <a data-v-426df720="" class="mint-cell mint-field required select">
      <div class="mint-cell-left"></div>
      <div class="mint-cell-wrapper">
        <div class="mint-cell-title">
          <span class="mint-cell-text">3. 今后您或您的朋友还会继续让我们为您提供服务吗？</span>
        </div>
        <div class="mint-cell-value sex">
          <div class="male">
            <input type="radio" v-model="form.jixuyong" value="true" id="jixuyong1" name="jixuyong" />
            <label for="jixuyong1">会</label>
          </div>
          <div class="male">
            <input type="radio" v-model="form.jixuyong" value="false" id="jixuyong2" name="jixuyong" />
            <label for="jixuyong2">不会</label>
          </div>
        </div>
      </div>
      <div class="mint-cell-right"></div>
    </a>
    <mt-field  rows="5" placeholder="建议、吐槽......都可以，我们会认真听取你所说的每一句话！" label="4. 您认为本公司的服务需要改进的地方：" class="textarea"  type="textarea" v-model="form.text1"></mt-field>
    <mt-field   rows="5"   placeholder="建议、吐槽......都可以，我们会认真听取你所说的每一句话！"  label="5. 您对艾尔兴广东服务中心的建议：" class="textarea"  type="textarea" v-model="form.text2"></mt-field>
    <mt-button style="margin-top: 50px;height: 50px ; margin-bottom: 50px; background-color: rgb(92, 65, 43);border-radius: 0;" class="user-btn" @click="submit" type="primary">提交</mt-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Toast } from 'mint-ui'
import _ from 'lodash'
const defaultForm = {
  fullName: '',
  fuwushuiping: '',
  fuwutaidu: '',
  fuwuzhonglei: '',
  zhengtishuiping: '',
  jixuyong: '',
  text1: '',
  text2: ''
}
export default {
  name: 'user-info-page',
  data() {
    return {
      form: _.cloneDeep(defaultForm),
    }
  },
  computed: mapState({
    user: state => state.user.user
  }),
  mounted: function () {
    this.form = Object.assign({}, this.form)
  },
  methods: {
    submit () {
      const body = Object.assign({}, this.form)
      if (!body.fullName) {
        return  Toast({
            message: '请填写孩子姓名',
            duration: 3000
          })
      }
      if (!body.fuwushuiping) {
        return  Toast({
            message: '请对服务人员技术水平进行评价',
            duration: 3000
          })
      }
      if (!body.fuwutaidu) {
        return  Toast({
            message: '请对服务人员的态度进行评价',
            duration: 3000
          })
      }
      if (!body.fuwuzhonglei) {
        return  Toast({
            message: '请对服务种类进行评价',
            duration: 3000
          })
      }
      if (!body.zhengtishuiping) {
        return  Toast({
            message: '请对整体技术水平进行评价',
            duration: 3000
          })
      }
      if (!body.jixuyong) {
        return  Toast({
            message: '请选择必填项',
            duration: 3000
          })
      }


      this.$store.httpDispatch({})('addQA', body).then((res) => {
        const { code, data } = res.data
        if (code === 0) {
          Toast({
            message: '提交成功',
            duration: 3000
          })
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
<style lang="less">
.qa-page {
  box-sizing: border-box;
  padding: 0 0;
  padding-top: 0;
  background: #EDDBC7;
  select {
    background: #fff!important;
  }
  input {
    background: #fff!important;
  }
  .qa-header {
    background-color: rgb(237, 219, 199);
    zoom: 1;
    min-height: 214px;
    background-image: url(../../assets/qa_title.jpeg);
    background-repeat: no-repeat;
    background-position: 50% 0%;
    background-size: 100%;
    h1 {
      text-align: left;
      padding: 29px 29px;
      margin: 0 0;
    }
  }
  .user-btn {
    height: 35px;
    line-height: 35px;
    background: #007EC5;
    width: 80vw;
    border-radius: 100px;
    margin-bottom: 20px;
  }
  .required {
    .mint-cell-title::after {
      position: absolute;
      content: '* ';
      left: 2px;
      top: 10px;
      color: red;
    }
  }
  .required-long {
    .mint-cell-title::after {
      position: absolute;
      content: '* ';
      left: 160px;
      top: 5px;
      color: red;
    }
  }
  .city-picker {
    .mint-cell-value {
      div {
        font: 400 11px system-ui;
        font-size: inherit;
        width: 80px;
        border: 0;
      }
    }
  }
  .textarea {
    .mint-cell-value {
    height: auto!important;

    }
  }
  .select
 {
    .mint-cell-value {
      border-radius: 5px;
      background: none!important;;
    }
 }
  .mint-cell {
    background: none;
    margin-bottom: 5px;
    .mint-cell-wrapper {
      border: 0;
      display: block;
      background: none;
    }
    .mint-cell-title {
      .mint-cell-text {
        display: inline-block;
        text-align: left;
        width: 100%;
        vertical-align: middle;
        line-height: 40px;
      }
      width: 100%;
      margin-right: 20px;
    }
    .mint-cell-value {
      border-radius: 5px;
      background: #fff;
      height: 45px;
      padding-left: 5px;
      select {
        width: 100%;
        font-size: 16px;
        border: 0;
        background: #ffff;
      }
    }
  }
  .mint-popup-bottom {
    width: 100vw;
  }
  .checkbox-item {
    margin: 0 10px;
  }
  .male {
    display: inline-block;
    margin: 0 20px 0 10px;
    margin-right: 3em;
    input[type="radio"] + label::before {
      border: 1px solid #017ECB;
    }
    input[type="radio"]:checked + label::before {
      background-color: #017ECB;
    }
  }
  .female {
    display: inline-block;
    input[type="radio"] + label::before {
      border: 1px solid #E293B3;
    }
    input[type="radio"]:checked + label::before {
      background-color: #E293B3;
    }
  }
  .sex {
    border-radius: 0px!important;
  }
  .sex input[type="radio"] + label::before {
    content: "\a0"; /*不换行空格*/
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    width: 1em;
    height: 1em;
    margin-right: .4em;
    border-radius: 50%;
    text-indent: .15em;
    line-height: 1;
  }
  .sex input[type="radio"]:checked + label::before {
    width: 0.6em;
    height: 0.6em;
    background-clip: content-box;
    padding: .2em;
  }
  .sex input[type="radio"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }
}
</style>
